<script setup lang="ts">
import type { Promotion } from "@/types";

const { t } = useT();

defineProps<{
	type?: Promotion["type"];
	image: string;
	title: string;
	entries: number;
}>();

const { isFlipTheCoinAvailable, typeValue } = useFlipTheCoin();

const emit = defineEmits<{ (event: "onCountDownStop"): void }>();
const onCountDownStop = () => {
	emit("onCountDownStop");
};

const { durationLeft, reset } = useCountdown({
	timestamp: typeValue.value,
	formatToken: "HH[:]mm[:]ss",
	onCountdownStop: onCountDownStop
});

watch(
	() => typeValue.value,
	(value) => {
		if (value) {
			reset(value);
		}
	}
);
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 280,
			height: 160,
			src: image,
			format: 'avif',
			loading: 'lazy'
		}"
		width="330px"
		height="310px"
	>
		<template #top>
			<ABadge v-if="!isFlipTheCoinAvailable" :background="'var(--a-overlay-50)'" variant="info" autosize>
				<AText type="small" as="div">
					{{ t("Next in") }} <b>{{ durationLeft }}</b>
				</AText>
			</ABadge>
			<AText type="base" :modifiers="['bold', 'uppercase']">
				{{ title }}
			</AText>
		</template>
		<template #bottom>
			<AText as="h3" type="base" :modifiers="['center', 'uppercase', 'bold']" class="text">
				{{ t("Get even more hot offers") }}
			</AText>
			<AButton
				:variant="'primary'"
				:modifiers="[!isFlipTheCoinAvailable ? 'disabled' : '']"
				size="s"
				class="promo"
				data-tid="promo-flip-coin"
			>
				{{ t("Try now for free") }}
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card {
	background: linear-gradient(338deg, #5900b2 10.58%, #00c2ff 50.54%, #34ff48 92.13%);
}

.text {
	max-width: 160px;
}
</style>
